import PropTypes from 'prop-types'
import React from 'react'
import { Button } from 'react-bootstrap'
import { downloadTemplate, lerPlanilhaAsync } from 'util/excelAdapter'

import { createProductsObjectsAnuncio } from 'util/importar-produtos-lote'
import { isNullOrEmpty } from 'util/utils'
import importarNaturalDaTerra from './naturalDaTerra'

export default class ImportarPlanilhaTela extends React.Component {
  static propTypes = {
    anuncioProdutos: PropTypes.array.isRequired,
    produtos: PropTypes.array.isRequired,
    onImportar: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)

    this.state = {
      onModeImportar: false,
      onModeSuccess: false,
      isLoading: false
    }
  }

  goToImportar = () => this.setState({ onModeImportar: true, onModeSuccess: false })
  goBackFromImportar = () => this.setState({ onModeImportar: false })

  handleOnFileChangeEvent = async (e) => {
    const { anuncioProdutos, produtos, onImportar } = this.props

    if (!isNullOrEmpty(e.target) && e.target.files !== undefined && e.target.files.length > 0) {
      try {
        const rows = await lerPlanilhaAsync(e.target.files[0])
        const dadosPlanilha = createProductsObjectsAnuncio(rows)
        const resultado = importarNaturalDaTerra(anuncioProdutos, dadosPlanilha, produtos)

        if (onImportar !== undefined) {
          onImportar(resultado)
        }

        this.setState({
          onModeImportar: false,
          onModeSuccess: true
        })
      } catch (erro) {
        console.log('erro ao carregar a planilha', erro)
      }
    }
  }

  handleDownloadTemplate = async () => {
    try {
      await downloadTemplate('Planilha_AtualizaçãoProdutos')
    } catch (error) {
      console.error('Erro ao baixar o template:', error)
    }
  }

  renderNormalMode = () => {
    return (
      <div className='text-right'>
        <Button variant='primary' size='sm' onClick={() => this.goToImportar()}>
          Importar produtos por planilha
        </Button>
      </div>
    )
  }

  renderSuccess = () => {
    return (
      <>
        Planilha importada com sucesso.
        <Button variant='primary' size='sm' onClick={() => this.goToImportar()}>
          Importar outra planilha
        </Button>
      </>
    )
  }

  renderImportarMode = () => {
    return (
      <>
        <div>
          <p align='center' className='margin'>
            Baixe{' '}
            <button className='link' onClick={this.handleDownloadTemplate}>
              aqui
            </button>{' '}
            o modelo de planilha.
          </p>
          <div className='d-flex justify-content-center'>
            <label className='btn btn-primary' htmlFor='upload'>
              Enviar planilha
              <input id='upload' type='file' onChange={this.handleOnFileChangeEvent} />
            </label>
          </div>
        </div>
      </>
    )
  }

  render () {
    const { onModeImportar, onModeSuccess } = this.state

    if (onModeSuccess) {
      return this.renderSuccess()
    }

    if (onModeImportar) {
      return this.renderImportarMode()
    }

    return this.renderNormalMode()
  }
}
