import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Container, Row, Table, Button, Alert, ButtonGroup } from 'react-bootstrap'
import { loadProducts, sendProduct, deleteProduct, addProduct, clearProducts } from 'store/importar-produtos-lote/actions'
import { status, addStatusAndOptions, getProdutosFiltrados } from 'util/importar-produtos-lote'
import { TableList, ListHeaders, ListHeaderTitle, ListBody, ListBodyRow, ListBodyCol } from 'components/style/tableList'
import { isArrayNotEmpty } from 'util/utils'
import { ExcelButtons } from 'components/estoque/ExcelButtons'
import SearchBar from 'components/search-bar'

import Modal from 'react-bootstrap/Modal'
import { useHistory } from 'react-router-dom'
import { loadProdutos, deleteProduto } from 'store/produto/actions'
import 'bootstrap-icons/font/bootstrap-icons.css'

import 'styles/produto.css'

const searchOptions = {
  shouldSort: true,
  minMatchCharLength: 2,
  threshold: 0.2,
  keys: [
    { name: 'nomeProduto', weight: 2 },
    { name: 'descricaoProduto', weight: 0.6 },
    { name: 'categoriaEntity.nomeCategoria', weight: 0.4 },
    { name: 'categoriaEntity.nomeCategoria.filhos.nomeCategoria', weight: 0.2 }
  ]
}
function ModalImportarCatalogo ({ show, finish, dispatch, listaProdutos }) {
  function RenderizarTabela () {
    if (!listaProdutos || listaProdutos.constructor !== Array || listaProdutos.length === 0) return null
    return (
      <Container>
        <Row>
          <Table className='table-hover text-sm' responsive>
            <thead>
              <tr>
                <th className='text-center'>Status</th>
                <th>Código</th>
                <th>Nome</th>
                <th className='text-right'>Ações</th>
              </tr>
            </thead>
            <tbody>
              {listaProdutos.map((produto) => {
                const { descricaoStatus, paraEnvio, idInterno } = produto.controle
                const { codigoInterno, nomeProduto } = produto.detalhes
                const { naoEnviado, enviando, enviado, erro } = status
                if (idInterno) {
                  return (
                    <tr key={idInterno}>
                      <td className='p-0'>
                        <Alert
                          className='text-center m-0'
                          variant={
                            paraEnvio === false
                              ? 'warning'
                              : descricaoStatus === naoEnviado
                              ? 'secondary'
                              : descricaoStatus === enviando
                              ? 'info'
                              : descricaoStatus === enviado
                              ? 'success'
                              : 'danger'
                          }
                        >
                          {descricaoStatus}
                        </Alert>
                      </td>
                      <td>{codigoInterno}</td>
                      <td>{nomeProduto}</td>
                      <td className={`text-right px-0 ${paraEnvio ? '' : 'd-none'}`}>
                        <ButtonGroup size='md'>
                          <Button
                            name='enviar'
                            className='px-4'
                            codproduto={idInterno}
                            onClick={() => dispatch(sendProduct(idInterno, produto.detalhes))}
                            disabled={!(descricaoStatus === naoEnviado || descricaoStatus === erro)}
                          >
                            Enviar
                          </Button>
                          <Button
                            className='px-4'
                            codproduto={idInterno}
                            variant='danger'
                            onClick={() => dispatch(deleteProduct(idInterno))}
                            disabled={!(descricaoStatus === naoEnviado || descricaoStatus === erro)}
                          >
                            Cancelar
                          </Button>
                        </ButtonGroup>
                      </td>

                      <td className={`text-right px-0 ${!paraEnvio ? '' : 'd-none'}`}>
                        <ButtonGroup size='lg'>
                          <Button name='adicionarParaEnvio' codproduto={idInterno} className='m-1' variant='warning' onClick={() => dispatch(addProduct(idInterno))}>
                            Adicionar para envio
                          </Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                  )
                }
                return null
              })}
            </tbody>
          </Table>
        </Row>
      </Container>
    )
  }
  return (
    <Modal dialogClassName='modal-90w' show={show} onHide={finish} size='lg' centered aria-labelledby='contained-modal-title-vcenter'>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Importar Produtos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <RenderizarTabela />
      </Modal.Body>
      <Modal.Footer>
        <Button name='finalizar' variant='primary' onClick={finish}>
          Finalizar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const TelaCatalogo = ({ produtos, isCatalogo, listaProdutos, dispatch, sendProduct }) => {
  const history = useHistory()
  const [hasSearch, setHasSearch] = useState(false)
  const [todosProdutos, setTodosProdutos] = useState(getProdutosFiltrados(produtos))
  const produtosComEstoque = getProdutosFiltrados(produtos.filter((x) => x.estoque > 0))
  const [produtosSemEstoque] = useState(getProdutosFiltrados(produtos.filter((x) => x.estoque <= 0)))
  const [showModal, setShowModal] = useState(false)
  const DisplayProdutos = () => (
    <TableList>
      <ListHeaders>
        <ListHeaderTitle>Código do Produto</ListHeaderTitle>
        <ListHeaderTitle>Nome do Produto</ListHeaderTitle>
        <ListHeaderTitle className='text-right'>Excluir Produto</ListHeaderTitle>
      </ListHeaders>
      <ListBody>{isArrayNotEmpty(produtos) ? todosProdutos.map((produto) => displayProdutoItem(produto)) : <DisplayEmptyList />}</ListBody>
    </TableList>
  )

  const displayProdutoItem = (produto) => (
    <ListBodyRow key={produto.idProduto}>
      <ListBodyCol first format='text' className='align-middle'>
        {produto.codigoInterno}
      </ListBodyCol>
      <ListBodyCol first format='text' className='align-middle'>
        {produto.nomeProduto}
      </ListBodyCol>
      <ListBodyCol last format='actionButtons'>
        <button type='button' className='btn btn-primary btn-danger' onClick={() => dispatch(deleteProduto(produto.idProduto))}>
          <i className='bi bi-trash' />
        </button>
      </ListBodyCol>
    </ListBodyRow>
  )

  const DisplayEmptyList = () => (
    <ListBodyRow>
      <ListBodyCol first format='text'>
        Não existem produtos na categoria selecionada
      </ListBodyCol>
    </ListBodyRow>
  )

  const ProdutosList = () => {
    return (
      <TableList>
        <ListHeaders>
          <ListHeaderTitle>Informações dos Produtos</ListHeaderTitle>
        </ListHeaders>
        <ListBody>{isArrayNotEmpty(produtos) ? <DisplayProdutos /> : <DisplayEmptyList />}</ListBody>
      </TableList>
    )
  }
  const changeItems = ({ result: items, query }) => {
    setHasSearch(!!query)
    setTodosProdutos(items)
  }
  const onImport = (prods) => {
    dispatch(clearProducts())
    const formatedProducts = prods.map((produto) => {
      const prod = {
        detalhes: produto
      }
      return prod
    })
    dispatch(loadProducts(addStatusAndOptions(formatedProducts)))
    setShowModal(true)
  }
  const ButtonsExcel = ({ produtosComEstoque, produtosSemEstoque, isCatalogo }) => {
    const importar = (event) => {
      onImport(event)
    }
    return (
      <div className='d-flex justify-content-end align-items-center'>
        <button name='novoProduto' onClick={() => history.push('/produtos/catalogo')} style={{ marginTop: '-12px', marginRight: '20px' }} className='btn btn-primary '>
          Novo Produto
        </button>
        <ExcelButtons produtosComEstoque={produtosComEstoque} produtosSemEstoque={produtosSemEstoque} onImport={importar} isCatalogo />
      </div>
    )
  }
  return (
    <>
      <ButtonsExcel produtosComEstoque={produtosComEstoque} produtosSemEstoque={produtosSemEstoque} isCatalogo={isCatalogo} />
      <SearchBar hasSearch={hasSearch} items={produtos} options={searchOptions} placeholder='Procurar no catalogo' onSearchItems={changeItems} />
      <ProdutosList />
      <ModalImportarCatalogo
        show={showModal}
        finish={() => {
          dispatch(loadProdutos())
          setShowModal(false)
        }}
        dispatch={dispatch}
        listaProdutos={listaProdutos}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  categorias: state.importarProdutos.categorias,
  listaProdutos: state.importarProdutos.produtos,
  produtos: state.produto.produtos
})
const mapDispatchToProps = (dispatch) => ({
  dispatch,
  loadProducts,
  sendProduct,
  deleteProduct,
  deleteProduto,
  addProduct
})

export default connect(mapStateToProps, mapDispatchToProps)(TelaCatalogo)
