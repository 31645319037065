import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'

import { Container, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faQuestion, faTimes } from '@fortawesome/free-solid-svg-icons'

import { trails } from 'util/trails'

import { Screen } from 'components/style'
import { screenSize } from 'util/utils'

import 'styles/status.css'

const Status = (props) => {
  const statusData = [
    {
      status: 'sucesso',
      title: 'Ótimo, foi finalizado o formulário com sucesso',
      caption: 'Iremos analisar sua resposta e você terá acesso as oportunidades para o seu perfil',
      icon: faCheck,
      color: 'bg-success'
    },
    {
      status: 'incompleto',
      title: 'Seu formulario está incompleto',
      caption: 'Clique em repetir e responda o formalurio novamente.',
      icon: faQuestion,
      color: 'bg-warning'
    },
    {
      status: 'naoaprovado',
      title: 'Infelizmente, você ainda não pode ser validado',
      caption: 'Suas respostas não estão adequadas para validar o seu acesso. Fale conosco para saber como conseguir ou tente novamente.',
      icon: faTimes,
      color: 'bg-danger'
    }
  ]
  const statusTypes = statusData.map((item) => item.status)
  const [screenSize, setScreenSize] = useState('md')
  const status = props.match.params.status

  useEffect(() => {
    setScreenSize(screenSize())

    window.addEventListener('resize', () => {
      setScreenSize(screenSize())
    })

    return () => {
      window.removeEventListener('resize', () => {})
    }
  }, [])

  const getSearchParams = () => {
    return new URLSearchParams(props.location.search)
  }

  const getTrailUrl = () => {
    const params = getSearchParams()

    const items = Object.keys(trails).reduce((total, current) => {
      return [...total, ...trails[current]]
    }, [])
    const trail = items.filter((el) => el.idform === params.get('idform'))[0]

    if (!trail) return '/perfil'
    return `${trail.url}#idform=${trail.idform}&nome=${props.login.nomeUsuario}&cpf=${props.login.nomeUsuario}`
  }
  const ButtonBack = ({ block }) => (
    <Button block={block} variant='outline-primary' href='/perfil'>
      VOLTAR
    </Button>
  )

  const ButtonRetry = ({ block, url }) => (
    <Button variant='primary' block={block} href={url}>
      REPETIR
    </Button>
  )
  const RenderButtons = () => {
    const trailUrl = getTrailUrl()
    return (
      <div className={`py-3 d-flex justify-content-center${screenSize !== 'lg' ? ' flex-column' : ''}`}>
        {status !== 'sucesso' && <ButtonRetry block={screenSize !== 'lg'} url={trailUrl} />}
        <ButtonBack block={screenSize !== 'lg'} />
      </div>
    )
  }
  const RenderStatusComponent = ({ status }) => {
    const data = statusData.find((item) => item.status === status)
    if (data) {
      return (
        <div className='card-body py-2'>
          <h4 className='card-title text-center'>{data.title}</h4>
          <h6 className='card-caption text-center'>{data.caption}</h6>
          <div className='py-5 d-flex align-items-center justify-content-center'>
            <div className={`status-icon rounded-circle ${data.color}`}>
              <FontAwesomeIcon icon={data.icon} size='lg' />
            </div>
          </div>
        </div>
      )
    }
    return null
  }

  if (statusTypes.includes(status)) {
    return (
      <Screen middle>
        <Container>
          <RenderStatusComponent status={status} />
          <RenderButtons />
        </Container>
      </Screen>
    )
  } else return <Redirect to='/perfil' />
}

const mapStateToProps = (state) => ({
  login: state.login,
  perfil: state.perfil
})

const mapDispatchToProps = (dispatch) => ({
  dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(Status)
