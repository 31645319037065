export default {
  ENTREGA: {
    DOADOR: {
      LOAD: {
        ALL: {
          BEGIN: 'DOACAO_ENTREGA_DOADOR_LOAD_ALL_BEGIN',
          SUCCESS: 'DOACAO_ENTREGA_DOADOR_LOAD_ALL_SUCCESS',
          FAILURE: 'DOACAO_ENTREGA_DOADOR_LOAD_ALL_FAILURE'
        }
      },
      ATIVAR: {
        BEGIN: 'DOACAO_ENTREGA_DOADOR_ATIVAR_BEGIN',
        SUCCESS: 'DOACAO_ENTREGA_DOADOR_ATIVAR_SUCCESS',
        FAILURE: 'DOACAO_ENTREGA_DOADOR_ATIVAR_FAILURE'
      },
      DESATIVAR: {
        BEGIN: 'DOACAO_ENTREGA_DOADOR_DESATIVAR_BEGIN',
        SUCCESS: 'DOACAO_ENTREGA_DOADOR_DESATIVAR_SUCCESS',
        FAILURE: 'DOACAO_ENTREGA_DOADOR_DESATIVAR_FAILURE'
      },
      POST: {
        BEGIN: 'DOACAO_ENTREGA_DOADOR_POST_BEGIN',
        SUCCESS: 'DOACAO_ENTREGA_DOADOR_POST_SUCCESS',
        FAILURE: 'DOACAO_ENTREGA_DOADOR_POST_FAILURE'
      },
      UPDATE: {
        BEGIN: 'DOACAO_ENTREGA_DOADOR_PUT_BEGIN',
        SUCCESS: 'DOACAO_ENTREGA_DOADOR_PUT_SUCCESS',
        FAILURE: 'DOACAO_ENTREGA_DOADOR_PUT_FAILURE'
      },
      BYID: {
        BEGIN: 'DOACAO_ENTREGA_DOADOR_BYID_BEGIN',
        SUCCESS: 'DOACAO_ENTREGA_DOADOR_BYID_SUCCESS',
        FAILURE: 'DOACAO_ENTREGA_DOADOR_BYID_FAILURE'
      },
      CLEARERROR: 'DOACAO_ENTREGA_DOADOR_CLEAR_ERROR',
      CLEARSUCCESS: 'DOACAO_ENTREGA_DOADOR_CLEAR_SUCCESS',
      SEMRESERVA: 'DOACAO_ENTREGA_DOADOR_NAO_HAVERA_RESERVA'
    }
  }
}
