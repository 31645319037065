import { Row, Button, FormControl } from 'react-bootstrap'
import React, { useState } from 'react'
import Select from 'react-select'
import NumberFormat from 'react-number-format'

function Step1 ({ next, nome, updating }) {
  const [update, setUpdate] = useState(updating)
  return (
    <>
      <div className='text-center mb-3 h5'>{nome}</div>
      <div className='text-center mb-1'>Você está alterando a quantidade do lote ou substituindo?</div>
      <div className='d-flex flex-column align-items-center'>
        <Button className='mt-4' onClick={() => setUpdate(true)} style={{ width: '200px' }} variant={update ? 'dark' : 'outline-dark'} color='primary'>
          Alterando
        </Button>
        <Button className='mt-3' onClick={() => setUpdate(false)} style={{ width: '200px' }} variant={update === false ? 'dark' : 'outline-dark'} color='primary'>
          Substituindo
        </Button>
      </div>
      <div className='mt-5 pt-3'>
        <Row className='justify-content-center pl-5 ml-3'>
          <Button onClick={() => next({ updating: update })} variant='dark'>
            Próximo
          </Button>
        </Row>
      </div>
    </>
  )
}
function Step2 ({ unidade, nome, estoque, next, back }) {
  const unidadesBase = ['CX', 'KG', 'UN', 'SACO']
  const [valor, setValor] = useState(estoque)
  const [medida, setMedida] = useState(unidade)
  const unidades = unidadesBase.map((item) => {
    return { value: item, label: item }
  })
  return (
    <>
      <div className='text-center mb-3 h5'>{nome}</div>
      <div className='text-center mb-4'>Qual medida e quantidade?</div>
      <div className='d-flex flex-column align-items-center'>
        <div style={{ width: '200px' }}>
          <Select placeholder='Medida' options={unidades} value={{ value: medida, label: `Medida: ${medida}` }} onChange={(e) => setMedida(e.value)} />
        </div>
        <div style={{ width: '200px' }}>
          <FormControl className='mt-3 py-1 w-100 w-max' placeholder='qtde' type='number' value={valor} key='valor' autoComplete='off' onChange={(e) => setValor(e.target.value)} />
        </div>
      </div>
      <div className='mt-5 pt-3'>
        <Row className='justify-content-center'>
          <Button onClick={() => next({ quantidade: valor, estoque: valor, unidade: medida })} variant='dark'>
            Próximo
          </Button>
        </Row>
      </div>
    </>
  )
}
function Step3 ({ valor, nome, next, back }) {
  const [valorBase, setValor] = useState(valor)
  return (
    <>
      <div className='text-center mb-3 h5'>{nome}</div>
      <div className='text-center mb-4'>Qual é o preço de venda atual?</div>
      <div className='d-flex flex-column align-items-center'>
        <div style={{ width: '200px' }}>
          <NumberFormat
            className='mt-3 form-control py-1 w-100 w-max'
            prefix='R$ '
            thousandSeparator='.'
            decimalScale={2}
            min={0}
            decimalSeparator=','
            value={valorBase}
            onValueChange={({ value }) => setValor(Number(value))}
          />
        </div>
      </div>
      <div className='mt-5 pt-3'>
        <Row className='justify-content-center'>
          <Button onClick={() => back({ preco: valorBase })} className='mr-3' variant='outline-secondary' color='primary'>
            Voltar
          </Button>
          <Button onClick={() => next({ preco: valorBase })} variant='dark'>
            Próximo
          </Button>
        </Row>
      </div>
    </>
  )
}
function Step4 ({ lote, nome, next, back, updating }) {
  const [valor, setValor] = useState(lote)
  return (
    <>
      <div className='text-center mb-3 h5'>{nome}</div>
      <div className='text-center mb-4'>Qual é o lote?</div>
      <div className='d-flex flex-column align-items-center'>
        <div style={{ width: '200px' }}>
          <FormControl disabled={updating} className='mt-3 py-1 w-100 w-max' placeholder='Valor' value={valor} autoComplete='off' onChange={(e) => setValor(e.target.value)} />
        </div>
      </div>
      <div className='mt-5 pt-3'>
        <Row className='justify-content-center'>
          <Button className='mr-3' onClick={() => back({ lote: valor })} variant='outline-secondary' color='primary'>
            Voltar
          </Button>
          <Button onClick={() => next({ lote: valor })} variant='dark'>
            Próximo
          </Button>
        </Row>
      </div>
    </>
  )
}
function Step5 ({ validade, nome, next, back, updating, isFormValid }) {
  const [valor, setValor] = useState(validade)
  return (
    <>
      <div className='text-center mb-3 h5'>{nome}</div>
      <div className='text-center  mb-4'>Qual é a validade?</div>
      <div className='d-flex flex-column align-items-center'>
        <div style={{ width: '200px' }}>
          <FormControl
            disabled={updating}
            className='mt-3 py-1 w-100 w-max'
            placeholder='Valor'
            type='date'
            value={valor}
            key='valor'
            autoComplete='off'
            onChange={(e) => setValor(e.target.value)}
          />
        </div>
      </div>
      <div className='mt-5 pt-3'>
        <Row className='justify-content-center'>
          <Button onClick={() => back({ validade: valor })} className='mr-3' variant='outline-secondary' color='primary'>
            Voltar
          </Button>
          <Button
            // disabled={!isFormValid}
            onClick={() => next({ validade: valor })}
            variant='dark'
          >
            Finalizar
          </Button>
        </Row>
      </div>
    </>
  )
}
export default { Step1, Step2, Step3, Step4, Step5 }
