import { Row, Button, FormControl } from 'react-bootstrap'
import React, { useState } from 'react'
import Select from 'react-select'

// function Step1({ next, produto, onChange }) {
//     const [idProduto, setIdProduto] = useState(produto.idProduto);

//     const handleNext = () => {
//         onChange('idProduto', idProduto);
//         next();
//     };

//     return (
//         <>
//             <div className="text-center mb-4">ID do Produto:</div>
//             <div className="d-flex flex-column align-items-center">
//                 <div style={{ width: '200px' }}>
//                     <FormControl
//                         className="mt-3 py-1 w-100 w-max"
//                         placeholder="ID Produto"
//                         value={idProduto}
//                         autoComplete="off"
//                         onChange={(e) => setIdProduto(e.target.value)}
//                     />
//                 </div>
//             </div>
//             <div className="mt-5 pt-3">
//                 <Row className="justify-content-center">
//                     <Button onClick={handleNext} variant="dark">
//                         Próximo
//                     </Button>
//                 </Row>
//             </div>
//         </>
//     );
// }
function Step2 ({ next, back, produto, onChange }) {
  const [codigoInterno, setcodigoInterno] = useState(produto.codigoInterno)

  const handleNext = () => {
    onChange('codigoInterno', codigoInterno)
    next()
  }

  return (
    <>
      <div className='text-center mb-4'>Código interno:</div>
      <div className='d-flex flex-column align-items-center'>
        <div style={{ width: '200px' }}>
          <FormControl
            name='codigoInterno'
            className='mt-3 py-1 w-100 w-max'
            placeholder='Código do Produto'
            value={codigoInterno}
            autoComplete='off'
            onChange={(e) => setcodigoInterno(e.target.value)}
          />
        </div>
      </div>
      <div className='mt-5 pt-3'>
        <Row className='justify-content-center'>
          <Button onClick={() => back()} variant='outline-secondary' color='primary'>
            Voltar
          </Button>
          <Button name='proximo' onClick={handleNext} variant='dark'>
            Próximo
          </Button>
        </Row>
      </div>
    </>
  )
}
function Step3 ({ next, back, produto, onChange }) {
  const [nomeProduto, setNomeProduto] = useState(produto.nomeProduto)

  const handleNext = () => {
    onChange('nomeProduto', nomeProduto)
    next()
  }

  return (
    <>
      <div className='text-center mb-4'>Nome do Produto:</div>
      <div className='d-flex flex-column align-items-center'>
        <div style={{ width: '200px' }}>
          <FormControl
            name='nomeProduto'
            className='mt-3 py-1 w-100 w-max'
            placeholder='Nome do Produto'
            value={nomeProduto}
            autoComplete='off'
            onChange={(e) => setNomeProduto(e.target.value)}
          />
        </div>
      </div>
      <div className='mt-5 pt-3'>
        <Row className='justify-content-center'>
          <Button onClick={() => back()} variant='outline-secondary' color='primary'>
            Voltar
          </Button>
          <Button name='proximo' onClick={handleNext} variant='dark'>
            Próximo
          </Button>
        </Row>
      </div>
    </>
  )
}

function Step4 ({ next, back, produto, onChange }) {
  const [codigoBarras, setCodigoBarras] = useState(produto.codigoBarras)

  const handleNext = () => {
    onChange('codigoBarras', codigoBarras)
    next()
  }

  return (
    <>
      <div className='text-center mb-4'>Código de Barras:</div>
      <div className='d-flex flex-column align-items-center'>
        <div style={{ width: '200px' }}>
          <FormControl
            name='codigoBarras'
            className='mt-3 py-1 w-100 w-max'
            placeholder='Código de Barras'
            value={codigoBarras}
            autoComplete='off'
            onChange={(e) => setCodigoBarras(e.target.value)}
          />
        </div>
      </div>
      <div className='mt-5 pt-3'>
        <Row className='justify-content-center'>
          <Button onClick={() => back()} variant='outline-secondary' color='primary'>
            Voltar
          </Button>
          <Button name='proximo' onClick={handleNext} variant='dark'>
            Próximo
          </Button>
        </Row>
      </div>
    </>
  )
}

function Step5 ({ next, back, produto, onChange }) {
  const [descricao, setDescricao] = useState(produto.descricao)

  const handleNext = () => {
    onChange('descricao', descricao)
    next()
  }

  return (
    <>
      <div className='text-center mb-4'>Descrição:</div>
      <div className='d-flex flex-column align-items-center'>
        <div style={{ width: '200px' }}>
          <FormControl
            name='descricao'
            className='mt-3 py-1 w-100 w-max'
            placeholder='Descrição'
            value={descricao}
            autoComplete='off'
            onChange={(e) => setDescricao(e.target.value)}
          />
        </div>
      </div>
      <div className='mt-5 pt-3'>
        <Row className='justify-content-center'>
          <Button onClick={() => back()} variant='outline-secondary' color='primary'>
            Voltar
          </Button>
          <Button name='proximo' onClick={handleNext} variant='dark'>
            Próximo
          </Button>
        </Row>
      </div>
    </>
  )
}

function Step6 ({ next, back, produto, onChange }) {
  const [precoBase, setPrecoBase] = useState(produto.precoBase)

  const handleNext = () => {
    onChange('precoBase', precoBase)
    next()
  }

  return (
    <>
      <div className='text-center mb-4'>Preço Base:</div>
      <div className='d-flex flex-column align-items-center'>
        <div style={{ width: '200px' }}>
          <FormControl
            name='precoBase'
            className='mt-3 py-1 w-100 w-max'
            placeholder='Preço Base'
            type='number'
            value={precoBase}
            autoComplete='off'
            onChange={(e) => setPrecoBase(e.target.value)}
          />
        </div>
      </div>
      <div className='mt-5 pt-3'>
        <Row className='justify-content-center'>
          <Button onClick={() => back()} variant='outline-secondary' color='primary'>
            Voltar
          </Button>
          <Button name='proximo' onClick={handleNext} variant='dark'>
            Próximo
          </Button>
        </Row>
      </div>
    </>
  )
}

function Step7 ({ finish, back, produto, onChange }) {
  const [unidadeBase, setUnidadeBase] = useState(produto.unidadeBase || 'KG')
  const unidadesBase = ['CX', 'KG', 'UN', 'SACO']
  const handleNext = () => {
    finish({ unidadeBase })
  }
  const unidades = unidadesBase.map((item) => {
    return { value: item, label: item }
  })
  return (
    <>
      <div className='text-center mb-4'>Unidade Base:</div>
      <div className='d-flex flex-column align-items-center'>
        <div name='unidadeBase' style={{ width: '200px' }}>
          <Select placeholder='Medida' options={unidades} value={{ value: unidadeBase, label: `Medida: ${unidadeBase}` }} onChange={(e) => setUnidadeBase(e.value)} />
        </div>
      </div>
      <div className='mt-5 pt-5'>
        <Row className='justify-content-center'>
          <Button onClick={() => back()} variant='outline-secondary' color='primary'>
            Voltar
          </Button>
          <Button name='finalizar' onClick={handleNext} variant='dark'>
            Finalizar
          </Button>
        </Row>
      </div>
    </>
  )
}

export default { Step2, Step3, Step4, Step5, Step6, Step7 }
