import ExcelJS from 'exceljs'
import FileSaver from 'file-saver'
import readXlsxFile from 'read-excel-file'

export const gerarPlanilha = async (produtos, isCatalogo) => {
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet(isCatalogo ? 'Planilha de Catálogo' : 'Planilha de Estoque')

  worksheet.columns = obterColunasExcel(isCatalogo)
  worksheet.addRows(isCatalogo ? [] : produtos)

  return workbook
}

export const obterColunasExcel = (isCatalogo) => {
  return isCatalogo
    ? [
        { header: 'Código do Produto', key: 'codigoInterno' },
        { header: 'Nome do Produto', key: 'nomeProduto' },
        { header: 'Descrição do Produto', key: 'descricao' },
        { header: 'Código de barras', key: 'codigoDeBarras' },
        { header: 'Unidade Base', key: 'unidadeBase' },
        { header: 'Preço Base', key: 'precoBase' }
      ]
    : [
        { header: 'Código do Produto', key: 'codigoInterno' },
        { header: 'Nome do Produto', key: 'nomeProduto' },
        { header: 'Quantidade', key: 'quantidade' },
        { header: 'Preço Base', key: 'precoBase' },
        { header: 'Unidade Base', key: 'unidadeBase' },
        { header: 'Validade', key: 'vencimento' },
        { header: 'Lote', key: 'numLote' }
      ]
}

export const baixarPlanilha = async (workbook, fileName) => {
  const buffer = await workbook.xlsx.writeBuffer()
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'
  const blob = new Blob([buffer], { type: fileType })
  FileSaver.saveAs(blob, fileName + fileExtension)
}

export const lerPlanilhaAsync = async (file) => {
  try {
    const rows = await readXlsxFile(file)
    return rows
  } catch (error) {
    console.error('Erro ao ler a planilha', error)
    throw error
  }
}

export const gerarTemplatePlanilha = () => {
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('PlanilhaPadrão')

  const baseTableFields = [
    {
      idProduto: '9998881676654',
      nomeProduto: 'Abacate',
      preco: '3',
      quantidade: '2',
      unidade: 'KG'
    }
  ]

  worksheet.columns = [
    { header: 'Id Produto', key: 'idProduto' },
    { header: 'Nome do Produto', key: 'nomeProduto' },
    { header: 'Quantidade', key: 'quantidade' },
    { header: 'Preço', key: 'preco' },
    { header: 'Unidade', key: 'unidade' }
  ]
  worksheet.addRows(baseTableFields)

  return workbook
}

export const downloadTemplate = async (fileName) => {
  const workbook = gerarTemplatePlanilha()
  const buffer = await workbook.xlsx.writeBuffer()
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'
  const blob = new Blob([buffer], { type: fileType })
  FileSaver.saveAs(blob, fileName + fileExtension)
}
