export default {
  PRODUTO: {
    BYID: {
      BEGIN: 'MODPRODUTOS_PRODUTO_BYID_BEGIN',
      SUCCESS: 'MODPRODUTOS_PRODUTO_BYID_SUCCESS',
      FAILURE: 'MODPRODUTOS_PRODUTO_BYID_FAILURE'
    },
    BYIDCATEGORIA: {
      BEGIN: 'MODPRODUTOS_PRODUTO_BYIDCATEGORIA_BEGIN',
      SUCCESS: 'MODPRODUTOS_PRODUTO_BYIDCATEGORIA_SUCCESS',
      FAILURE: 'MODPRODUTOS_PRODUTO_BYIDCATEGORIA_FAILURE'
    },
    LOAD: {
      BEGIN: 'MODPRODUTOS_PRODUTO_LOAD_BEGIN',
      SUCCESS: 'MODPRODUTOS_PRODUTO_LOAD_SUCCESS',
      FAILURE: 'MODPRODUTOS_PRODUTO_LOAD_FAILURE'
    },
    POST: {
      BEGIN: 'MODPRODUTOS_PRODUTO_POST_BEGIN',
      SUCCESS: 'MODPRODUTOS_PRODUTO_POST_SUCCESS',
      FAILURE: 'MODPRODUTOS_PRODUTO_POST_FAILURE'
    },
    PATCH: {
      BEGIN: 'MODPRODUTOS_PRODUTO_PATCH_BEGIN',
      SUCCESS: 'MODPRODUTOS_PRODUTO_PATCH_SUCCESS',
      FAILURE: 'MODPRODUTOS_PRODUTO_PATCH_FAILURE'
    },
    IMAGENS: {
      POST: {
        BEGIN: 'MODPRODUTOS_PRODUTO_IMAGENS_POST_BEGIN',
        SUCCESS: 'MODPRODUTOS_PRODUTO_IMAGENS_POST_SUCCESS',
        FAILURE: 'MODPRODUTOS_PRODUTO_IMAGENS_POST_FAILURE'
      },
      DELETE: {
        BEGIN: 'MODPRODUTOS_PRODUTO_IMAGENS_DELETE_BEGIN',
        SUCCESS: 'MODPRODUTOS_PRODUTO_IMAGENS_DELETE_SUCCESS',
        FAILURE: 'MODPRODUTOS_PRODUTO_IMAGENS_DELETE_FAILURE'
      }
    }
  },
  ESTOQUE: {
    LOTE: {
      POST: {
        BEGIN: 'MOVIMENTOESTOQUE_LOTE_POST_BEGIN',
        SUCCESS: 'MOVIMENTOESTOQUE_LOTE_POST_SUCCESS',
        FAILURE: 'MOVIMENTOESTOQUE_LOTE_POST_FAILURE'
      }
    }
  }
}
