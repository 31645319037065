import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Alert, Button, Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import Switch from 'react-switch'
import TelaDeErro from 'components/telaDeErro'
import InputNumber from 'components/input/inputNumberConfirm'
import ObservationTextArea from 'components/observationTextArea'
import Loading from 'components/loading'
import Refresh from 'components/refresh'
import { loadProdutos } from 'store/produto/actions'
import { saveReserva, clearError } from './action'
import { loadReservaById } from '../../../../store/reserva-doacao/action'
import ProdutosList from '../anuncio/components/ProdutosList'
import BotaoSalvar from 'components/BotaoSalvar'

import RenderInformacoes from 'components/renderInformacaoEmpresa'

import './entrega.css'
import { isNullOrEmpty } from 'util/utils'
import { isDoador, isDonatario } from 'util/perfil'
import { Screen } from 'components/style'

import SectionHandler from 'components/contrato/sectionHandler'

import Dropzone from '../../../../components/Dropzone'
class EntregaDoadorEditar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      reservaProdutos: [],
      contrato: {},
      idReserva: '',
      naoLocalizado: false,
      responsavelEntrega: '',
      notafiscalEntrega: '',
      observacaoEntrega: '',
      isActive: true,
      ranking: '',
      idReservaInteracao: '',
      reserva: {},
      isLoadingDetalheEntrega: true,
      checked: false,
      onAddMode: false,
      produtosAdicionais: [],
      fotos: []
    }
  }

  componentDidMount () {
    const {
      match: { params },
      dispatch,
      loadReservaById
    } = this.props
    dispatch(loadProdutos())
    const idReservaInteracao = params.id
    dispatch(loadReservaById(idReservaInteracao, { begin: 'a', success: 'b', failure: 'c' })).then((result) => {
      if (result && result.payload && result.payload.interacoes && result.payload.interacoes.length > 0) {
        this.handleLoadReservaResult(result)
      } else {
        console.log('não foi localizado a reserva para essa entrega')
        this.setState({ naoLocalizado: true, isLoadingDetalheEntrega: false })
      }
    })
  }

  handleLoadReservaResult (result) {
    const reservaProdutos = result.payload.ultimaInteracao.reservaProdutos
    const entregasRealizadas = result.payload.ultimaInteracao.entregas
    const newReservaProdutos = reservaProdutos.map((produto) => ({
      ...produto,
      quantidadeRetiradaAnunciante: produto.quantidade
    }))
    this.setState({ entregasRealizadas }, () => this.loadEntregaDetails())
    this.setState({ idReservaInteracao: result.payload.interacoes[result.payload.interacoes.length - 1].id })
    this.setState({ reservaProdutos: newReservaProdutos })
    this.setState({ reserva: result.payload, isLoadingDetalheEntrega: false })
  }

  loadEntregaDetails () {
    const isEntregaDone = this.isEntregaRealizada()
    if (isEntregaDone === true) {
      const { entregasRealizadas } = this.state

      if (Array.isArray(entregasRealizadas) && entregasRealizadas.length > 0) {
        const entrega = entregasRealizadas[0]

        this.setState({
          observacaoEntrega: entrega.observacoes,
          notafiscalEntrega: entrega.numeroNotaFiscal,
          responsavelEntrega: entrega.quemBuscou,
          ranking: entrega.ranking
        })
      }
    }
  }

  renderObservacoesAnuncio = (anuncio) => {
    if (anuncio.observacaoAnuncio !== '') {
      return (
        <p>
          <b>Observação do anúncio: </b>
          {`${anuncio.observacaoAnuncio}`}
        </p>
      )
    } else {
      return (
        <>
          Sem observações de anúncio
          <br />
        </>
      )
    }
  }

  renderObservacoesReserva = (anuncio) => {
    if (anuncio.interacoes && anuncio.interacoes.length > 0 && anuncio.interacoes[0].observacao !== undefined) {
      return (
        <p>
          <b>Observação da reserva: </b>
          {`${anuncio.interacoes[0].observacao}`}
        </p>
      )
    } else {
      return <>Sem observações de reserva</>
    }
  }

  onChangeQuantidade (qtd, idProduto) {
    const { reservaProdutos } = this.state
    const index = reservaProdutos.findIndex((produto) => produto.idProduto === idProduto)
    const qtdReservada = parseFloat(qtd)

    reservaProdutos[index].quantidadeRetiradaAnunciante = qtdReservada >= 0.0 ? qtdReservada : parseFloat(0)

    this.setState({ reservaProdutos })
  }

  createObjectToApi () {
    const entregaObject = {
      entregaProdutos: this.state.reservaProdutos.map((produto) => ({
        id: null,
        idReservaProduto: produto.id,
        idProduto: produto.idProduto,
        // unidade: null,
        nomeProduto: produto.nomeProduto,
        quantidade: produto.quantidadeRetiradaAnunciante
        // observacoes: "string",
      })),
      realizada: moment()
        .local()
        .format(),
      quemBuscou: this.state.responsavelEntrega,
      numeroNotaFiscal: this.state.notafiscalEntrega,
      motivoDivergencia: this.state.observacaoEntrega,
      ranking: this.state.ranking,
      observacoes: this.state.observacaoEntrega,
      idReservaInteracao: this.state.idReservaInteracao
    }

    return entregaObject
  }

  onSubmit () {
    const { dispatch, saveReserva } = this.props
    dispatch(saveReserva(this.createObjectToApi()))
  }

  renderLinhaProduto (item) {
    return (
      <tr key={item.idProduto}>
        <td>
          {item.nomeProduto} ({item.unidade})
        </td>
        <td className='text-center'>{item.quantidade}</td>
        <td>
          <InputNumber
            id={item.idProduto}
            valor={item.quantidadeRetiradaAnunciante}
            min={0}
            max={item.quantidade}
            precision={2}
            confirmacao={false}
            onChange={(e) => this.onChangeQuantidade(e, item.idProduto)}
            isDoador={isDoador}
            isDonatario={isDonatario}
          />
        </td>
      </tr>
    )
  }

  renderTabelaProduto (produtos) {
    return (
      <div className='table-responsive my-3'>
        <table className='table table-striped table-hover table-sm'>
          <thead>
            <tr>
              <th className='text-center' scope='col'>
                Nome Produto (UN)
              </th>
              <th className='text-center' scope='col'>
                Quantidade reservada
              </th>
              <th className='text-center' scope='col'>
                Quantidade entregue/retirada
              </th>
            </tr>
          </thead>
          <tbody>{produtos.map((produto) => this.renderLinhaProduto(produto))} </tbody>
        </table>
      </div>
    )
  }

  handleHide = () => {
    this.setState({
      isActive: false
    })
  }

  renderFailAlert () {
    const { isFailedToSaveEntrega } = this.props
    const { reservaProdutos } = this.state
    for (let i = 0; i < reservaProdutos.length; i++) {
      if (isFailedToSaveEntrega === true && reservaProdutos[i].qtdReservada !== reservaProdutos[i].quantidadeRetiradaAnunciante) {
        return <Alert variant='danger'>Indique nas observações o motivo da divergência na quantidade do produto reservado e entregue.</Alert>
      }
    }
  }

  isEntregaRealizada () {
    const { entregasRealizadas } = this.state
    return Array.isArray(entregasRealizadas) && entregasRealizadas.length > 0
  }

  renderActionButtons () {
    const { pristine, submitting, invalid, isLoadingBtn } = this.props
    const { checked } = this.state
    let nota
    if (isNullOrEmpty(this.state.notafiscalEntrega)) {
      if (checked) nota = false
      else nota = true
    } else {
      nota = false
    }
    if (this.isEntregaRealizada()) {
      return (
        <>
          <hr className='my-3' />
          <Alert variant='info'>
            <strong>Entrega já registrada. </strong>As informações apresentadas são apenas para fins de visualização.
          </Alert>
        </>
      )
    } else {
      return (
        <div className='row justify-content-end'>
          <div className='col-md-3 my-2'>
            <BotaoSalvar onClick={() => this.onSubmit()} disabled={pristine || submitting || invalid || isLoadingBtn || isNullOrEmpty(this.state.responsavelEntrega) || nota} />
          </div>
        </div>
      )
    }
  }

  selectAllProducts = () => {
    this.state.reservaProdutos.forEach((item) => this.onChangeQuantidade(item.quantidade, item.idProduto))
  }

  onChangeCheckBox = (value) => this.setState({ checked: value })
  onChangeTextArea = (value) => this.setState({ observacaoEntrega: value })
  onChangeResponsavel = (value) => this.setState({ responsavelEntrega: value })
  onChangeNotaFiscal = (value) => this.setState({ notafiscalEntrega: value })

  handleOnAdd = (id, nome, produto) => {
    const { onAdd } = this.props

    this.setState({ onAddMode: false }, onAdd(id, nome, produto))
  }

  handleEtapa1Dados = () => {
    return (
      <>
        <div className='row justify-content-between'>
          <h5>Verificar produtos reservados</h5>
        </div>
        {this.renderTabelaProduto(this.state.reservaProdutos)}
      </>
    )
  }

  alterarAnuncioProduto = (ap, unidade, quantidade, nomeProduto) => ({
    produto: {
      ...ap,
      unidade,
      quantidade,
      nomeProduto
    },
    ...ap,
    unidade,
    quantidade
  })

  handleOnChangeProduto = (idProduto, unidade, quantidade) => {
    const { produtosAdicionais } = this.state
    const anuncioProdutos = produtosAdicionais.map((product) => {
      const { produto } = product
      console.log(product)
      if (produto.idProduto === idProduto) {
        return {
          produto,
          ...produto,
          quantidade,
          unidade
        }
      } else {
        return {
          ...product
        }
      }
    })

    this.setState({ produtosAdicionais: anuncioProdutos })
    console.log(this.state.produtosAdicionais)
  }

  handleOnRemoveProduto = (idProduto) => {
    const { produtosAdicionais } = this.state

    this.setState({
      produtosAdicionais: produtosAdicionais.filter((ap) => ap.idProduto !== idProduto)
    })
  }

  handleEtapa2Vazia = () => {
    const RenderAddNew = () => {
      const { produtosCadastrados } = this.props
      const { onAddMode, produtosAdicionais } = this.state
      const SelectProdutos = () => (
        <>
          <h5>Inclusão de novos produtos:</h5>
          <ProdutosList
            anuncioProdutos={produtosAdicionais}
            produtos={produtosCadastrados}
            onChange={this.handleOnChangeProduto}
            onRemove={this.handleOnRemoveProduto}
            onAdd={(idProduto) => {
              const produto = produtosCadastrados.find((x) => x.idProduto === idProduto)
              this.setState({ onAddMode: false })
              this.setState({ produtosAdicionais: [...produtosAdicionais, { produto, ...produto, quantidade: 1, unidade: produto.unidadeBase || 'KG' }] })
              setTimeout(() => {
                console.log(this.state.produtosAdicionais)
              })
            }}
            isEstoqueProduto={false}
          />
        </>
      )
      return <SelectProdutos />
    }
    return <RenderAddNew />
  }

  handleEtapa3Fotos = () => {
    const RenderAddNew = () => {
      const { fotos } = this.state
      const SelectProdutos = () => (
        <>
          <h5>Inclusão de fotos dos produtos:</h5>
          <Dropzone
            multiple
            callback={(nome, type, content) => {
              this.setState({ fotos: [...fotos, { nome, type, content }] })
            }}
          />
          <div className='d-flex mt-5 flex-wrap row-cols-3 row-cols-sm-4 row-cols-md-5 row-cols-lg-6'>
            {fotos.map((foto, i) => (
              <div className='col position-relative'>
                <img src={foto.content} alt={foto.nome} className='img-fluid' />
                <Button
                  className='position-absolute text-white'
                  style={{ top: '-5px', right: '5px' }}
                  variant='danger'
                  onClick={() => {
                    this.setState({ fotos: fotos.filter((f, j) => j !== i) })
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </div>
            ))}
          </div>
        </>
      )
      return <SelectProdutos />
    }
    return <RenderAddNew />
  }

  handleEtapa4NotaFiscal = () => {
    return (
      <>
        <h5>Dados da coleta:</h5>
        <div>
          <label>* Itens obrigatórios</label>
        </div>
        <div className='form-group'>
          <label htmlFor='nome'>Responsável pela entrega/retirada: *</label>
          <textarea onChange={(e) => this.onChangeResponsavel(e.target.value)} rows={1} className='form-control' value={this.state.responsavelEntrega} required />
          <label htmlFor='nome'>Número da Nota Fiscal: *</label>
          <textarea
            onChange={(e) => this.onChangeNotaFiscal(e.target.value)}
            rows={1}
            className='form-control'
            value={this.state.notafiscalEntrega}
            disabled={this.state.checked}
          />
        </div>
        <div className='d-flex align-items-center mb-2'>
          <Switch className='mr-2' onChange={(e) => this.setState({ checked: e })} uncheckedIcon={false} checkedIcon={false} checked={this.state.checked || false} />
          Não possui nota
        </div>
        <ObservationTextArea
          onChange={(e) => this.onChangeTextArea(e.target.value)}
          ref={this.textAreaRef}
          maxchars={500}
          labeltextarea='Observações: '
          textarearows='3'
          value={this.state.observacaoEntrega}
        />

        <div className='form-group'>
          <div>Como você classificaria a entrega?</div>
          <div className='rate' onChange={(e) => this.setState({ ranking: e.target.value })}>
            <input type='radio' id='star5' name='rate' value='5' />
            <label htmlFor='star5' title='5 estrelas'>
              5 estrelas
            </label>
            <input type='radio' id='star4' name='rate' value='4' />
            <label htmlFor='star4' title='4 estrelas'>
              4 estrelas
            </label>
            <input type='radio' id='star3' name='rate' value='3' />
            <label htmlFor='star3' title='3 estrelas'>
              3 estrelas
            </label>
            <input type='radio' id='star2' name='rate' value='2' />
            <label htmlFor='star2' title='2 estrelas'>
              2 estrelas
            </label>
            <input type='radio' id='star1' name='rate' value='1' />
            <label htmlFor='star1' title='1 estrela'>
              1 estrela
            </label>
          </div>
        </div>

        {this.renderActionButtons()}
      </>
    )
  }

  render () {
    const { connection, dispatch, isLoadingBtn, isPosting, isLoadingReserva, hasError, errors } = this.props
    const { reserva, isLoadingDetalheEntrega } = this.state

    if (connection) {
      return <Refresh error={connection} dispatch={dispatch} />
    }

    if (isLoadingDetalheEntrega || isLoadingBtn || isLoadingReserva) {
      return <Loading />
    }

    if (reserva === undefined || reserva === null || reserva.id === undefined) {
      return <>Não foi possível localizar o id informado</>
    }

    if (hasError) {
      return <TelaDeErro error={errors} callbackReturn={() => dispatch(clearError())} message='Erro ao tentar cadastrar anuncio' />
    }

    return this.state.isActive ? (
      <Screen back={{ to: '/doacao/entrega-doador/listar', title: 'Entregas' }}>
        <Container>
          <div className='mt-4 mb-4 caixa'>
            {this.renderFailAlert()}
            <RenderInformacoes reserva={reserva} />
            <SectionHandler
              etapas={[
                {
                  etapa: 1,
                  callback: this.handleEtapa1Dados,
                  ativa: true
                },
                {
                  etapa: 2,
                  callback: this.handleEtapa4NotaFiscal,
                  ativa: false
                }
              ]}
              etapaInicial={1}
              finalizar={false}
            />
          </div>
        </Container>
      </Screen>
    ) : (
      <Screen back={{ to: '/doacao/entrega-doador/listar', title: 'Entregas' }}>
        <div className='container margin'>
          <h4 className='margin'> Não vai retirar? Diga-nos o porquê: *</h4>
          <ObservationTextArea
            className='margin'
            onChange={(e) => this.onChangeTextArea(e.target.value)}
            ref={this.textAreaRef}
            maxchars={500}
            required
            textarearows='3'
            value={this.state.observacaoEntrega}
          />
          <label>* Item obrigatório</label>
          <div className={`row justify-content-end container-actions ${this.props.isInViewport ? 'container-actions-absolute' : 'container-actions-absolute'}`}>
            <div className='col-md-3 col-sm-2 my-2'>
              <button
                className='btn btn-success btn-block'
                onClick={() => this.onSubmit()}
                disabled={isPosting || this.state.observacaoEntrega === '' || this.state.observacaoEntrega === undefined}
              >
                {isPosting ? <FontAwesomeIcon spin icon={faSpinner} /> : 'Salvar'}
              </button>
            </div>
          </div>
        </div>
      </Screen>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.doacaoEntregaDoador,
  isLoadingReserva: state.doacaoReserva.isLoading,
  connection: state.main.connection,
  isLoading: state.doacaoContrato.isLoading,
  produtosCadastrados: state.produto.produtos
})

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  loadReservaById,
  saveReserva,
  loadProdutos
})

export default connect(mapStateToProps, mapDispatchToProps)(EntregaDoadorEditar)
