export default {
  CONTRATO: {
    LOAD: {
      ALL: {
        BEGIN: 'VENDA_CONTRATO_LOAD_ALL_BEGIN',
        SUCCESS: 'VENDA_CONTRATO_LOAD_ALL_SUCCESS',
        FAILURE: 'VENDA_CONTRATO_LOAD_ALL_FAILURE'
      },
      ATIVOS: {
        BEGIN: 'VENDA_CONTRATO_LOAD_ATIVOS_BEGIN',
        SUCCESS: 'VENDA_CONTRATO_LOAD_ATIVOS_SUCCESS',
        FAILURE: 'VENDA_CONTRATO_LOAD_ATIVOS_FAILURE'
      },
      INATIVOS: {
        BEGIN: 'VENDA_CONTRATO_LOAD_INATIVOS_BEGIN',
        SUCCESS: 'VENDA_CONTRATO_LOAD_INATIVOS_SUCCESS',
        FAILURE: 'VENDA_CONTRATO_LOAD_INATIVOS_FAILURE'
      }
    },
    ATIVAR: {
      BEGIN: 'VENDA_CONTRATO_ATIVAR_BEGIN',
      SUCCESS: 'VENDA_CONTRATO_ATIVAR_SUCCESS',
      FAILURE: 'VENDA_CONTRATO_ATIVAR_FAILURE'
    },
    DESATIVAR: {
      BEGIN: 'VENDA_CONTRATO_DESATIVAR_BEGIN',
      SUCCESS: 'VENDA_CONTRATO_DESATIVAR_SUCCESS',
      FAILURE: 'VENDA_CONTRATO_DESATIVAR_FAILURE'
    },
    POST: {
      BEGIN: 'VENDA_CONTRATO_POST_BEGIN',
      SUCCESS: 'VENDA_CONTRATO_POST_SUCCESS',
      FAILURE: 'VENDA_CONTRATO_POST_FAILURE'
    },
    PUT: {
      BEGIN: 'VENDA_CONTRATO_PUT_BEGIN',
      SUCCESS: 'VENDA_CONTRATO_PUT_SUCCESS',
      FAILURE: 'VENDA_CONTRATO_PUT_FAILURE'
    },
    BYID: {
      BEGIN: 'VENDA_CONTRATO_BYID_BEGIN',
      SUCCESS: 'VENDA_CONTRATO_BYID_SUCCESS',
      FAILURE: 'VENDA_CONTRATO_BYID_FAILURE'
    }
  }
}
