import React, { useState, useEffect } from 'react'
import Proptypes from 'prop-types'

import SelectAssinaturas from 'components/contrato/selectAssinaturas'
import SelectGrupos from 'components/contrato/selectGrupos'
import ListAssinaturas from 'components/contrato/listAssinaturas'
import ListGrupos from 'components/contrato/listGrupos'

function Etapa2Assinantes ({ assinados, todos, onAddAssinatura, onRemoveAssinatura, onAddGrupo, onRemoveGrupo, isDoacao }) {
  const [assinaturasDisponiveis, setAssinaturas] = useState()
  const [gruposDisponiveis, setGrupos] = useState()

  useEffect(() => {
    setAssinaturas(todos.assinaturas.filter((x) => !assinados.assinaturas.some((k) => k.idEmpresa === x.idEmpresa)))
  }, [todos.assinaturas, assinados.assinaturas])

  useEffect(() => {
    setGrupos(todos.grupos.filter((x) => !assinados.grupos.some((k) => k.idGrupo === x.id)))
  }, [todos.grupos, assinados.grupos])

  return (
    <form className='form marginPagamentoContrato'>
      <div className='card container'>
        <div className='card-body'>
          <h1>Contrato de {isDoacao ? 'doação' : 'venda'}</h1>

          <h4>Assinatura</h4>

          <div className='margin-form2x' name='select-assinatura'>
            <label>Selecione a outra parte do relacionamento *</label>
            <SelectAssinaturas assinantes={assinaturasDisponiveis} onAddAssinatura={onAddAssinatura} name='opcoes-assinatura' />
          </div>

          <div className='margin-form' name='assinaturas-atribuidas'>
            <label>Assinaturas atribuídas</label>
            <ListAssinaturas assinantes={assinados.assinaturas} onRemoveAssinatura={onRemoveAssinatura} />
          </div>

          <div className='margin-form2x'>
            <label>Adicionar grupo *</label>
            <SelectGrupos grupos={gruposDisponiveis} onAddGrupo={onAddGrupo} name='opcoes-grupo' />
          </div>

          <div className='margin-form' name='grupos-atribuídos'>
            <label>Grupos atribuídos</label>
            <ListGrupos grupos={assinados.grupos} onRemoveGrupo={onRemoveGrupo} />
          </div>

          <div>
            <label className='margin-form2x'>*É obrigatório o preenchimento de um dos itens.</label>
          </div>
        </div>
      </div>
    </form>
  )
}

Etapa2Assinantes.propTypes = {
  assinados: Proptypes.shape({
    assinaturas: Proptypes.array,
    grupos: Proptypes.array
  }).isRequired,
  todos: Proptypes.shape({
    assinaturas: Proptypes.array.isRequired,
    grupos: Proptypes.array.isRequired
  }).isRequired,
  onAddAssinatura: Proptypes.func.isRequired,
  onRemoveAssinatura: Proptypes.func.isRequired,
  onAddGrupo: Proptypes.func.isRequired,
  onRemoveGrupo: Proptypes.func.isRequired
}

export default Etapa2Assinantes
