import dayjs from 'dayjs'

export const getNow = () => dayjs()

export const getDate = (dateTime = new Date()) => {
  return dayjs(dateTime)
    .startOf('day')
    .format('YYYY-MM-DD[T]HH:mm')
}

export const getDateTime = (dateTime = new Date()) => {
  return dayjs(dateTime)
    .startOf('second')
    .format('YYYY-MM-DD[T]HH:mm')
}

export const getDateString = (dateTime = new Date()) => dayjs(dateTime).format('YYYY-MM-DD')

export const getTime = (dateTime = new Date()) => dayjs(dateTime).format('HH:mm')

export const getTimeString = (dateTime = new Date()) => dayjs(dateTime).format('HH:mm')

export const joinDateTime = (date, time) => {
  const formattedDate = dayjs(date).format('YYYY-MM-DD')
  const formattedTime = dayjs(time).format('HH:mm')
  return `${formattedDate}T${formattedTime}`
}

export const getDateTimeString = (dateTime = new Date()) => dayjs(dateTime).format('YYYY-MM-DDTHH:mm')

export const toJsonString = (dateTime = new Date()) => dayjs(dateTime).format('YYYY-MM-DDTHH:mm')

export const addMinutes = (datetime, minutes) =>
  dayjs(datetime)
    .add(minutes, 'minutes')
    .format('YYYY-MM-DD[T]HH:mm')
