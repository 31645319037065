import React, { useState } from 'react'
import { handleOnFileChangeEventAsync } from 'util/importar-produtos-lote'
import { Row, Button, Modal } from 'react-bootstrap'
import dayjs from 'dayjs'
import { isArrayNotEmpty } from 'util/utils'
import { isNumber } from 'lodash'
import { gerarPlanilha, baixarPlanilha } from 'util/excelAdapter'

export const ExcelButtons = ({ produtosComEstoque, produtosSemEstoque, onImport, isCatalogo }) => {
  const [showEmptyFileAlert, setShowEmptyFileAlert] = useState(false)
  const produtos = [...produtosComEstoque, ...produtosSemEstoque]

  const prods = produtos.map(({ unidadeBase, idProduto, idEmpresa, estoque, codigoInterno, nomeProduto, precoBase, ultimaModificacao }) => {
    const numLote = ultimaModificacao ? ultimaModificacao.numLote : ''
    const vencimento = ultimaModificacao ? ultimaModificacao.validade : ''
    const vencimentoFormatado = vencimento ? dayjs(vencimento, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm') : ''
    return {
      idEmpresa,
      idProduto,
      codigoInterno,
      nomeProduto,
      quantidade: Number(estoque) || 0,
      precoBase: Number(precoBase) || 0,
      unidadeBase,
      vencimento: vencimentoFormatado,
      numLote
    }
  })

  const formatProdCatalogo = ({ codigoInterno, nomeProduto, codigoDeBarras, descricao, precoBase, unidadeBase, vencimento, numLote }) => ({
    codigoInterno: codigoInterno.toString(),
    nomeProduto: typeof nomeProduto === 'string' ? nomeProduto.trim() : nomeProduto,
    descricao: typeof descricao === 'string' ? descricao.trim() : '',
    codigoDeBarras,
    precoBase: Number(precoBase) || 0,
    unidadeBase,
    vencimento,
    numLote
  })

  const importarPlanilhaCallBack = (rowsPlanilha, isCatalogo) => {
    if (!isArrayNotEmpty(rowsPlanilha)) {
      setShowEmptyFileAlert(true)
      return
    } else {
      setShowEmptyFileAlert(false)
      onImport(rowsPlanilha, isCatalogo)
    }

    try {
      if (isCatalogo) {
        const prods = rowsPlanilha.map((produto) => formatProdCatalogo(produto))
        onImport(prods, isCatalogo)
      } else {
        const toDate = (excelDate) => {
          if (!excelDate) return undefined
          if (isNumber(excelDate)) {
            return new Date((excelDate - (25567 + 2)) * 86400 * 1000)
          }
          const now = dayjs()
          const isHojeOuDepois = (date) => dayjs(date).isAfter(now) || dayjs(date).isSame(now)
          if (isHojeOuDepois(dayjs(excelDate, 'YYYY/MM/DD'))) {
            return dayjs(excelDate, 'YYYY/MM/DD').toDate()
          }
          if (isHojeOuDepois(dayjs(excelDate, 'DD/MM/YYYY'))) {
            return dayjs(excelDate, 'DD/MM/YYYY').toDate()
          }
          return undefined
        }

        const formatProd = (row) => {
          if (!row || !row.nomeProduto) {
            return {}
          }

          return {
            codigoInterno: row.codigoInterno.toString(),
            nomeProduto: row.nomeProduto.trim(),
            quantidade: Number(row.quantidade) || Number(row.estoque) || 0,
            precoBase: Number(row.precoBase) || 0,
            unidadeBase: row.unidadeBase,
            vencimento: toDate(row.vencimento) || undefined,
            numLote: row.numLote || undefined
          }
        }

        const alteracoesComEstoque = rowsPlanilha.filter((row) => {
          const produtoFormatado = formatProd(row)

          const produtoExistente = produtosComEstoque.find((produto) => produto.codigoInterno === produtoFormatado.codigoInterno)
          if (!produtoExistente) return false
          return Object.entries(produtoFormatado).some(([key, value]) => produtoExistente[key] !== value)
        })

        const alteracoesSemEstoque = rowsPlanilha.filter((row) => {
          const produtoFormatado = formatProd(row)
          const produtoExistente = produtosSemEstoque.find((produto) => produto.codigoInterno === produtoFormatado.codigoInterno)
          if (!produtoExistente) return false
          return Object.entries(produtoFormatado).some(([key, value]) => produtoExistente[key] !== value)
        })

        const formatedAlteracoesComEstoque = alteracoesComEstoque.map((produto) => {
          const [prod] = produtosComEstoque.filter((prod) => prod.codigoInterno === produto.codigoInterno)
          return {
            ...prod,
            ...produto
          }
        })

        const formatedAlteracoesSemEstoque = alteracoesSemEstoque.map((produto) => {
          const [prod] = produtosSemEstoque.filter((prod) => prod.codigoInterno === produto.codigoInterno)
          return {
            ...prod,
            ...produto
          }
        })

        const totalLength = alteracoesComEstoque.length + alteracoesSemEstoque.length

        const payload = {
          alteracoesComEstoque: formatedAlteracoesComEstoque,
          alteracoesSemEstoque: formatedAlteracoesSemEstoque,
          quantidadeAlteracoes: totalLength
        }

        onImport(payload)
      }
    } catch (error) {
      console.error('Ocorreu um erro ao importar a planilha:', error)
      return error
    }
  }

  const handleDownload = async () => {
    const workbook = await gerarPlanilha(prods, isCatalogo)
    const fileName = isCatalogo ? 'Planilha de Inclusão de Produtos' : 'Planilha Atualização de Produtos'
    await baixarPlanilha(workbook, fileName)
  }

  return (
    <Row className='float-right mb-3 '>
      <label htmlFor={`file-upload${isCatalogo ? 1 : 0}`} className='btn saveadd-primary-color' data-testid={`upload-button-${isCatalogo ? 'catalogo' : 'estoque'}`}>
        Enviar Planilha
      </label>
      <input
        type='file'
        id={`file-upload${isCatalogo ? 1 : 0}`}
        name={`file-upload${isCatalogo ? 1 : 0}`}
        onChange={async (event) => {
          event.persist()
          try {
            await handleOnFileChangeEventAsync(
              event,
              (e) => {
                importarPlanilhaCallBack(e, isCatalogo)
              },
              isCatalogo
            )
            event.target.value = null
          } catch (error) {
            return error
          }
        }}
      />
      <Button className='btn btn-primary' onClick={handleDownload}>
        {isCatalogo ? 'Baixar planilha de Catálogo' : 'Baixar planilha de Estoque'}
      </Button>

      <Modal show={showEmptyFileAlert} onHide={() => setShowEmptyFileAlert(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Atenção</Modal.Title>
        </Modal.Header>
        <Modal.Body>A planilha está vazia. Por favor, selecione outra planilha válida.</Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={() => setShowEmptyFileAlert(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Row>
  )
}

export default ExcelButtons
