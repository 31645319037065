export default {
  ANUNCIO: {
    LOAD: {
      ALL: {
        BEGIN: 'VENDA_ANUNCIO_LOAD_ALL_BEGIN',
        SUCCESS: 'VENDA_ANUNCIO_LOAD_ALL_SUCCESS',
        FAILURE: 'VENDA_ANUNCIO_LOAD_ALL_FAILURE'
      }
    },
    ATIVAR: {
      BEGIN: 'VENDA_ANUNCIO_ATIVAR_BEGIN',
      SUCCESS: 'VENDA_ANUNCIO_ATIVAR_SUCCESS',
      FAILURE: 'VENDA_ANUNCIO_ATIVAR_FAILURE'
    },
    DESATIVAR: {
      BEGIN: 'VENDA_ANUNCIO_DESATIVAR_BEGIN',
      SUCCESS: 'VENDA_ANUNCIO_DESATIVAR_SUCCESS',
      FAILURE: 'VENDA_ANUNCIO_DESATIVAR_FAILURE'
    },
    ADD: {
      BEGIN: 'VENDA_ANUNCIO_POST_BEGIN',
      SUCCESS: 'VENDA_ANUNCIO_POST_SUCCESS',
      FAILURE: 'VENDA_ANUNCIO_POST_FAILURE'
    },
    UPDATE: {
      BEGIN: 'VENDA_ANUNCIO_PUT_BEGIN',
      SUCCESS: 'VENDA_ANUNCIO_PUT_SUCCESS',
      FAILURE: 'VENDA_ANUNCIO_PUT_FAILURE'
    },
    BYID: {
      BEGIN: 'VENDA_ANUNCIO_BYID_BEGIN',
      SUCCESS: 'VENDA_ANUNCIO_BYID_SUCCESS',
      FAILURE: 'VENDA_ANUNCIO_BYID_FAILURE'
    },
    CLEARERROR: 'VENDA_ANUNCIO_CLEAR_ERROR',
    CLEARSUCCESS: 'VENDA_ANUNCIO_CLEAR_SUCCESS'
  }
}
