import React from 'react'
import PropTypes from 'prop-types'

export default class InputNumberComConfirm extends React.PureComponent {
  static propTypes = {
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    id: PropTypes.string.isRequired,
    valor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    step: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    style: PropTypes.object,
    confirmacao: PropTypes.bool,
    isDoador: PropTypes.bool,
    isDonatario: PropTypes.bool
  }

  handleChange = (e) => {
    const value = e.target.value
    const floatValue = parseFloat(value)

    if (!isNaN(floatValue)) {
      if (this.props.isDoador || this.props.isDonatario || !this.props.max || floatValue <= this.props.max) {
        this.props.onChange && this.props.onChange(floatValue, this.props.id)
      }
    } else {
      this.props.onChange && this.props.onChange('', this.props.id)
    }
  }

  handleClear = () => {
    this.setState({ valor: '' })
    this.props.onChange && this.props.onChange('', this.props.id)
  }

  renderButtonsAdd = () => {
    const { readOnly, disabled } = this.props
    if (!readOnly && !disabled) {
      return (
        <div className='input-group-append'>
          <button type='button' className='btn btn-secondary' onClick={this.handleClear}>
            Limpar
          </button>
        </div>
      )
    }
  }

  renderInput = () => {
    const { id, step, min, max, readOnly, disabled, className, style, valor } = this.props

    return (
      <div className='input-container' style={{ width: '100%' }}>
        <input
          name='input-number'
          type='number'
          id={id}
          value={valor > 0 ? valor : ''}
          className={`${className || 'form-control form-control-sm'} custom-input`}
          readOnly={readOnly || false}
          disabled={disabled || false}
          min={min}
          max={max}
          step={step}
          style={{ minWidth: '100%', ...style }}
          onChange={this.handleChange}
        />
        {this.renderButtonsAdd()}
      </div>
    )
  }

  renderAppend = () => {
    if (this.props.confirmacao) {
      return (
        <div className='input-group-append'>
          {this.renderCancelar()}
          {this.renderConfirmar()}
        </div>
      )
    } else {
      return null
    }
  }

  render () {
    return (
      <div className='input-group input-group-sm' style={{ width: '100%' }}>
        {this.renderInput()}
        {this.renderAppend()}
      </div>
    )
  }
}
